@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/Fonts/ProximaNova-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: 'Proxima Nova Medium';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/Fonts/proxima-nova-medium.ttf);
  font-display: swap;
}
@font-face {
  font-family:'Avenir-Medium';
  font-style: normal;
  font-weight: 400;
  src:url(./assets/Fonts/avenir-65-medium-latin-1-webfont.woff2) format('woff2'), url(./assets/Fonts/avenir-65-medium-latin-1.ttf);
  font-display: swap;
}
@font-face {
  font-family:'Avenir-Medium';
  font-style: oblique;
  font-weight: 400;
  src:url(./assets/Fonts/avenir-65-medium-oblique-latin-1-webfont.woff2) format('woff2'), url(./assets/Fonts/avenir-65-medium-oblique-latin-1.ttf);
  font-display: swap;
}
@font-face {
  font-family:'Avenir-Heavy';
  font-style: normal;
  font-weight: 400;
  src:url(./assets/Fonts/avenir-85-heavy-latin-1-webfont.woff2) format('woff2'), url(./assets/Fonts/avenir-85-heavy-latin-1.ttf);
  font-display: swap;
}
@font-face {
  font-family:'Avenir-Heavy';
  font-style: oblique;
  font-weight: 400;
  src:url(./assets/Fonts/avenir-85-heavy-oblique-latin-1-webfont.woff2) format('woff2'), url(./assets/Fonts/avenir-85-heavy-oblique-latin-1.ttf);
  font-display: swap;
}
body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  font-display: swap;
}

#root {
  display: flex;
  flex-direction: column;
  font-family: Avenir-Medium;
  font-display: swap;
}

code {
  font-family: 'Courier';
  font-display: swap;
}

@media all  and (-ms-high-contrast:none) {
  *::-ms-backdrop, .right-canvas-content-wrapper {
    height: unset;
    flex:auto
  }
}

.react-datepicker-popper {
  z-index: 9000 !important;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
      max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
      max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
      max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
      max-width: 1140px;
  }
}
